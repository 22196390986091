import { ADMINISTATIVE_CONTACT_EMAIL } from '~/constants';
import mailtoUtil from '~/utils/mailto.util';

const navigation: {
  title: string;
  link: string;
  shouldOpenNewWindow?: boolean;
  login_icon: boolean;
  highlighted: boolean;
}[] = [
  {
    title: 'over',
    link: '#over',
    login_icon: false,
    highlighted: false,
  },
  {
    title: 'voordelen',
    link: '#voordelen',
    login_icon: false,
    highlighted: false,
  },
  // @todo: unhide once copy is available
  // {
  //   title: 'klanten',
  //   link: '#klanten',
  //   login_icon: false,
  //   highlighted: false,
  // },
  {
    title: 'demo',
    link: '#demo',
    login_icon: false,
    highlighted: false,
  },
  {
    title: 'contact',
    link: '#contact',
    login_icon: false,
    highlighted: false,
  },
  {
    title: 'log in',
    link: '/login',
    shouldOpenNewWindow: true,
    login_icon: true,
    highlighted: false,
  },
];

export default navigation;
